export const searchboxStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    // borderColor: state.isFocused ? 'grey' : 'red',
    padding: '14px',
    borderRadius: '10px',
    marginRight: 10,
    // width: '70%',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    // width: '70%',
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontSize: '1.5rem',
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    fontSize: '1.5rem',
  }),
  multiValue: (baseStyles) => ({
    ...baseStyles,
    fontSize: '1.5rem',
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    fontSize: '1.5rem',
  }),
}
