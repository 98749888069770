export const detectSuburb = async (lat, lon) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`,
    )
    const data = await response.json()
    console.log('data dected', data)
    if (data.address && data.address.suburb) {
      return data.address.suburb
      //   setSuburb(data.address.suburb);
      //   console.log('sub',data.address.suburb)
    } else {
      //   setSuburb('Suburb not found');
      return ''
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    // setSuburb('Error fetching data');
    return ''
  }
}

//we need an efficient function that will scan a very large array of objects..
//each object has a key called 'categoryName' and we want to return an array of unique categoryNames
export const getUniqueCategoryNames = (arr) => {
  let unique = {}
  let distinct = []
  for (let i in arr) {
    if (typeof unique[arr[i].categoryName] == 'undefined') {
      distinct.push(arr[i].categoryName)
    }
    unique[arr[i].categoryName] = 0
  }
  return distinct
}

//1. ['chocolate', 'strawberry', 'vanilla', 'cocoa', 'coffee', 'tea', 'milk]

//2.  [{ value: 'chocolate', label: 'Chocolate' },
//     { value: 'strawberry', label: 'Strawberry' },
//     { value: 'vanilla', label: 'Vanilla' },
//     { value: 'cocoa', label: 'Cocoa' },
//     { value: 'coffee', label: 'Coffee' }],

export const createLabelsfromArray = (strArray) => {
  return strArray.map((item) => {
    return { value: item, label: item }
  })
}

//function to revert the above
export const createArrayFromLabels = (labelArray) => {
  return labelArray.map((item) => {
    return item.value
  })
}

//function to convert a string to human readable format and sentence case
export const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

//function to taken  url, if not truthy or undefined, return a #, else
//check if it has http or https, if not, add https
// start
export const formatUrl = (url) => {
  if (!url) {
    return '#'
  } else {
    if (url.indexOf('http') === -1) {
      return `https://${url}`
    } else {
      return url
    }
  }
}
