import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SearchPanelSection from '../components/panels/SearchPanelSection'
import BannerPanelSection from '../components/panels/BannerPanelSection'
// import ThirdSection from './pages_and_layouts/atrractionLandingpage/ThirdSection'
import data from '../assets/files/businesses.json'
import { useState } from 'react'
import { detectSuburb, getUniqueCategoryNames } from '../utils'
import { updateDetectedSuburb } from '../actions'
import { useDispatch } from 'react-redux'

const Home = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const business = params?.[0]
  const [geolocationSupported, setGeolocationSupported] = useState(false)
  const [suburb, setSuburb] = useState('')

  useEffect(() => {
    // console.log('ddd', data, 'params', business)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error)
    } else {
      console.log('Geolocation not supported')
    }

    async function success(position) {
      const latitude = position.coords.latitude
      const longitude = position.coords.longitude
      console.log('position', position)
      console.log(
        `Latitude: ${latitude}, Longitude: ${longitude}, posyion`,
        position,
      )

      const suburb = await detectSuburb(latitude, longitude)
      //add to local storage
      localStorage.setItem('@suburb', suburb)
      setSuburb(suburb)
      console.log('suburb---->>>>', suburb)
      updateDetectedSuburb(dispatch, suburb)

      setGeolocationSupported(true)
    }

    function error() {
      console.log('Unable to retrieve your location')
      setGeolocationSupported(false)
    }
  }, [business])

  return (
    <div>
      <SearchPanelSection geolocationSupported={geolocationSupported} />

      <BannerPanelSection />
    </div>
  )
}

export default Home
