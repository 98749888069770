import * as React from 'react'

import Button from '@mui/material/Button'
import styles from '../style/styleInner.module.css'
import debounceControll from '../../utils/DebounceControll'
import { useDispatch } from 'react-redux'
import data from '../../assets/files/businesses.json'
import Select from 'react-select'
import { searchboxStyles } from '../style/searchbox'
import { selectCategories } from '../../actions'
import { useSelector } from 'react-redux'
import { createArrayFromLabels, createLabelsfromArray } from '../../utils'
import { useHistory } from 'react-router-dom'

export default function SearchBox2(props) {
  console.log('props', props)
  const dispatch = useDispatch()
  const history = useHistory()
  const categoriesState = useSelector((state) => state.categories)
  const suburbsState = useSelector((state) => state.suburbs)
  //hooks used for debounce

  const [selectedMatch, setSelectedMatch] = React.useState()
  const [selectedPlaceMatch, setSelectedPlaceMatch] = React.useState()
  const inputRef = React.useRef(null)

  const handleChange = (selectedOptions) => {
    if (!props.fromResults) {
      selectCategories(dispatch, createArrayFromLabels(selectedOptions))
    } else {
      //props?.handleChange(createArrayFromLabels(selectedOptions))
      console.log('selecedsuburb', selectedOptions)
      props.setSuburbToSearch(selectedOptions.value)
    }
  }

  const proceed = () => {
    console.log('proceeding')
    //go to results page
    history.push({
      pathname: '/results',
    })
  }

  const returnOptions = () => {
    if (props.fromResults) {
      return createLabelsfromArray(suburbsState?.suburbs || [])
    }
    return createLabelsfromArray(categoriesState?.categories || [])
  }
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>
        {selectedMatch} {selectedPlaceMatch && ` | ${selectedPlaceMatch}`}
      </span>
      <div className={styles.topHeading}>
        {props?.fromResults ? 'Looking elsewhere?' : 'What are you looking for'}
      </div>

      <div className="row">
        <div className="col-12 col-md-8 col-lg-8">
          <Select
            options={returnOptions()}
            isMulti={!props.fromResults}
            onChange={handleChange}
            placeholder={
              props.fromResults
                ? 'Search a location'
                : 'Search for up to 3 categories'
            }
            maxMenuHeight={200}
            styles={searchboxStyles}
          />
        </div>
        {!props.fromResults && (
          <div className="col-12 col-md-4 col-lg-4">
            <div style={{ marginTop: '1%' }}>
              <Button
                variant="contained"
                className="p-4 ml-2"
                onClick={proceed}
                disabled={
                  categoriesState?.categoriesSelected?.length > 3 ||
                  categoriesState?.categoriesSelected?.length < 1
                }
              >
                Go
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
