import {
  FETCHING_SUBURBS,
  FETCHING_SUBURBS_SUCCESS,
  FETCHING_SUBURBS_FAILURE,
  UPDATING_DETECTED_SUBURB,
  UPDATING_SELECTED_SUBURBS,
} from '../actions/_types'

const initialState = {
  suburbs: [],
  suburbsSelected: [],
  suburbsLoading: false,
  suburbsError: null,
  detectedSuburb: '',
}

function suburbsReducer(state = initialState, { type, payload, error }) {
  switch (type) {
    case FETCHING_SUBURBS: {
      return {
        ...state,
        suburbs: [],
        suburbsLoading: true,
        suburbsError: null,
      }
    }
    case FETCHING_SUBURBS_SUCCESS: {
      return {
        ...state,
        suburbsLoading: false,
        suburbs: payload,
      }
    }
    case FETCHING_SUBURBS_FAILURE: {
      return {
        ...state,
        suburbsLoading: false,
        suburbsError: error,
      }
    }
    case UPDATING_DETECTED_SUBURB: {
      return {
        ...state,
        detectedSuburb: payload,
      }
    }
    case UPDATING_SELECTED_SUBURBS: {
      return {
        ...state,
        suburbsSelected: payload,
      }
    }

    default: {
      return state
    }
  }
}
export default suburbsReducer
