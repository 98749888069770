import React, { useEffect } from 'react'
import styles from '../style/styleInner.module.css'
import { Badge } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories } from '../../actions'
import SearchBox2 from '../search/SearchBox2'
const SearchPanelSection = ({
  geolocationSupported,

  fromResults,
  setSuburbToSearch,
}) => {
  let history = useHistory()
  const dispatch = useDispatch()
  // const categories = getUniqueCategoryNames(data)
  const categoriesState = useSelector((state) => state.categories)
  const suburbsState = useSelector((state) => state.suburbs)

  console.log('suburbsState', suburbsState)

  useEffect(() => {
    if (!fromResults) {
      getCategories(dispatch)
    }
  }, [])

  // console.log('datas', datas)
  return (
    <div className={styles.SearchPanelSection}>
      <div className="mb-5">
        <SearchBox2
          geolocationSupported={geolocationSupported}
          fromResults={!!fromResults}
          setSuburbToSearch={setSuburbToSearch}
        />
      </div>
      {/* {!fromResults && (
        <div className="mb-5">
          {categoriesState.categoriesLoading ? (
            <p>Loading categories...</p>
          ) : categoriesState.categoriesError ? (
            <p> {categoriesState.categoriesError}</p>
          ) : (
            <>
              <p className="mb-1">People are also searching for:</p>
              <div
                className="d-flex flex-row justify-content-aroundt mt-3"
                style={{ width: '70%' }}
              >
                {categoriesState?.categories?.map((category, index) => {
                  return (
                    <Badge key={index} className="m-1 bg-info">
                      {category}
                    </Badge>
                  )
                })}
              </div>
            </>
          )}
        </div>
      )} */}

      <div className="mt-3">
        {!!suburbsState?.detectedSuburb && (
          <p className={styles.smallFont}>
            <i className="fa fa-map-marker" /> {suburbsState?.detectedSuburb}
          </p>
        )}
      </div>
    </div>
  )
}

export default SearchPanelSection
