import {
  FETCHING_CATEGORIES,
  FETCHING_CATEGORIES_SUCCESS,
  FETCHING_CATEGORIES_FAILURE,
  UPDATING_SELECTED_CATEGORIES,
} from '../actions/_types'

const initialState = {
  // item: [],
  // loading: false,
  // error: null,
  // totalpages: 1
  categories: [],
  categoriesSelected: [],
  categoriesLoading: false,
  categoriesError: null,
}

function categoriesReducer(state = initialState, { type, payload, error }) {
  switch (type) {
    case FETCHING_CATEGORIES: {
      return {
        ...state,
        categories: [],
        categoriesLoading: true,
        categoriesError: null,
      }
    }
    case FETCHING_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categoriesLoading: false,
        categories: payload,
      }
    }
    case FETCHING_CATEGORIES_FAILURE: {
      return {
        ...state,
        categoriesLoading: false,
        categoriesError: error,
      }
    }
    case UPDATING_SELECTED_CATEGORIES: {
      return {
        ...state,
        categoriesSelected: payload,
      }
    }
    default: {
      return state
    }
  }
}
export default categoriesReducer
