import React from 'react'
import styles from '../style/styleInner.module.css'
import Card from '../common/Card'
import { Chip } from '@mui/material'
import { formatUrl } from 'utils'
const ResultsPane = ({ data, showCategories }) => {
  return (
    <>
      <div className={styles.thirdSection}>
        {data.map((business, index) => (
          <div>
            <a
              href={`${formatUrl(business?.website)}`}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: 'none',
                pointerEvents: !business?.website ? 'none' : 'auto',
              }}
              key={index}
            >
              <Card
                width="350px"
                height="200px"
                // backgroundImage={`/images/${business?.categoryName}/${business?.placeId}.jpg`}
                backgroundImage={`https://files.bestbusinessesintown.com/${business?.categoryName}/${business?.placeId}.jpg`}
                showCategories={showCategories}
                business={business}
                place={business?.title}
              />
            </a>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                margin: 15,
              }}
            >
              <span>
                {business?.phone ? (
                  <a
                    href={`tel:${business?.phone}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Chip
                      label={business?.phone}
                      variant="outlined"
                      color="primary"
                      icon={<i className="fa fa-phone" />}
                      clickable
                    />
                  </a>
                ) : (
                  ''
                )}
              </span>
              <span>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${business?.lat},${business?.lng}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <Chip
                    label="Directions"
                    variant="outlined"
                    color="warning"
                    icon={<i className="fa fa-map-marker" />}
                    clickable
                    // href={`https://www.google.com/maps/search/?api=1&query=${business?.lat},${business?.lng}`}
                  />
                </a>
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default ResultsPane
