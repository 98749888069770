import {
  FETCHING_BUSINESSES,
  FETCHING_BUSINESSES_SUCCESS,
  FETCHING_BUSINESSES_FAILURE,
} from '../actions/_types'

const initialState = {
  businesses: [],
  businessesLoading: false,
  businessesError: null,
}

function businessesReducer(state = initialState, { type, payload, error }) {
  switch (type) {
    case FETCHING_BUSINESSES: {
      return {
        ...state,
        businesses: [],
        businessesLoading: true,
        businessesError: null,
      }
    }
    case FETCHING_BUSINESSES_SUCCESS: {
      return {
        ...state,
        businessesLoading: false,
        businesses: payload,
      }
    }
    case FETCHING_BUSINESSES_FAILURE: {
      return {
        ...state,
        businessesLoading: false,
        businessesError: error,
      }
    }

    default: {
      return state
    }
  }
}
export default businessesReducer
