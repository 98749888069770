import React, { useEffect } from 'react'
import SearchPanelSection from '../components/panels/SearchPanelSection'

import data from '../assets/files/businesses.json'
import ResultsPane from '../components/panels/ResultsPane'
import { useLocation, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import {
  getBusinessesBySubandCats,
  getSuburbsPerCategoryArray,
} from '../actions'
import { Chip, Stack } from '@mui/material'
import Lottie from 'lottie-react'

const ResultsIndex = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const suburbsState = useSelector((state) => state.suburbs)
  const categoriesState = useSelector((state) => state.categories)
  const businessState = useSelector((state) => state.businesses)

  const [suburbToSearch, setSuburbToSearch] = useState(
    suburbsState.detectedSuburb,
  )
  useEffect(() => {
    /**2. ****fetch unique suburbs as per the categories selected**/
    const selectedCategories = categoriesState?.categoriesSelected
    getSuburbsPerCategoryArray(dispatch, selectedCategories)
  }, [])

  useEffect(() => {
    /**1. ****get the autodetected suburb if there is, and get the selected categories, do a search

    //if theres not a match, then user will select suburbs. when suburbs change(suburbsToSearch), redo the search*/
    if (suburbToSearch && categoriesState?.categoriesSelected) {
      getBusinessesBySubandCats(
        dispatch,
        suburbToSearch,
        categoriesState?.categoriesSelected,
      )
    }
    //componenet is unmounted
    // return () => {
    //   //cleanup
    //   setSuburbToSearch(suburbsState.detectedSuburb)
    // }
  }, [suburbToSearch])

  return (
    <div>
      <SearchPanelSection
        fromResults={true}
        setSuburbToSearch={setSuburbToSearch}
      />
      <div>
        <div className="container">
          <div className="row p-1">
            <div className="col-8 col-md-8">
              <p>
                <small>Categories searched:</small>
              </p>

              {categoriesState?.categoriesSelected?.length === 0 ? (
                <>
                  <small className="text-warning">
                    <i className="fa fa-exclamation-circle" /> &nbsp; category
                    not selected
                  </small>
                  <div className="ml-2 mt-1">
                    <Link to="/">
                      <small>Go to select</small>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <Stack direction="row" spacing={1}>
                    {categoriesState?.categoriesSelected?.map(
                      (category, index) => {
                        return <Chip key={index} label={category} />
                      },
                    )}
                  </Stack>
                  <div className="ml-2 mt-1">
                    <Link to="/">
                      <small>Select again</small>
                    </Link>
                  </div>
                </>
              )}
            </div>
            <div className="col-4 col-md-4">
              <p>
                <small>Suburb Searched:</small>
              </p>
              {suburbToSearch ? (
                <Chip
                  label={suburbToSearch}
                  variant="outlined"
                  color="primary"
                />
              ) : (
                <small className="text-warning">
                  <i className="fa fa-exclamation-circle" /> &nbsp; suburb not
                  selected
                </small>
              )}
            </div>
          </div>
        </div>
        <hr />
        <div className="results">
          {businessState?.businessesLoading ? (
            <Lottie
              animationData={require(`assets/animations/loader.json`)}
              loop={true}
              autoplay={true}
              style={{
                width: '50%',
                height: '70%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }} // Adjust width and height as needed
            />
          ) : businessState?.businessesError ? (
            <p> {businessState?.businessesError}</p>
          ) : (
            <div className="container">
              {/* <SecondSection /> */}
              {businessState?.businesses?.length > 0 ? (
                <ResultsPane
                  data={businessState?.businesses}
                  showCategories={
                    categoriesState?.categoriesSelected?.length > 1
                  }
                />
              ) : (
                <div className="container p-3">
                  <div style={{ padding: 20 }} className="alert alert-warning">
                    <h3>
                      {' '}
                      This category of businesses could not be found in your
                      current location. Try looking elsewhere above
                    </h3>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ResultsIndex
