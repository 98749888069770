import {
  getBusinessesBySubandCatsService,
  getCategoriesService,
  getSuburbsPerCategoryArrayService,
} from '../services'
import {
  FETCHING_BUSINESSES,
  FETCHING_BUSINESSES_FAILURE,
  FETCHING_BUSINESSES_SUCCESS,
  FETCHING_CATEGORIES,
  FETCHING_CATEGORIES_FAILURE,
  FETCHING_CATEGORIES_SUCCESS,
  FETCHING_SUBURBS,
  FETCHING_SUBURBS_FAILURE,
  FETCHING_SUBURBS_SUCCESS,
  UPDATING_DETECTED_SUBURB,
  UPDATING_SELECTED_CATEGORIES,
} from './_types'

//getCategories
export const getCategories = async (dispatch) => {
  //loading

  dispatch({ type: FETCHING_CATEGORIES, payload: null, error: null })
  try {
    const response = await getCategoriesService()
    if (!!response?.success) {
      dispatch({
        type: FETCHING_CATEGORIES_SUCCESS,
        payload: response.results,
      })
    } else {
      dispatch({
        type: FETCHING_CATEGORIES_FAILURE,
        payload: null,
        error: 'An error occured while fetching categories',
      })
    }
  } catch (error) {
    dispatch({
      type: FETCHING_CATEGORIES_FAILURE,
      payload: null,
      error: 'An error occured while fetching categories',
    })
  }
}

//selectCategories
export const selectCategories = (dispatch, payload) => {
  dispatch({ type: UPDATING_SELECTED_CATEGORIES, payload: payload })
}

//updated detected suburb
export const updateDetectedSuburb = (dispatch, payload) => {
  console.log('payload', payload)
  dispatch({ type: UPDATING_DETECTED_SUBURB, payload: payload })
}

//get suburbs
export const getSuburbsPerCategoryArray = async (dispatch, categories) => {
  //loading
  dispatch({ type: FETCHING_SUBURBS, payload: null, error: null })
  try {
    const response = await getSuburbsPerCategoryArrayService(categories || [])
    if (!!response?.success) {
      dispatch({
        type: FETCHING_SUBURBS_SUCCESS,
        payload: response.results,
      })
    } else {
      dispatch({
        type: FETCHING_SUBURBS_FAILURE,
        payload: null,
        error: 'An error occured while fetching suburbs',
      })
    }
  } catch (error) {
    dispatch({
      type: FETCHING_SUBURBS_FAILURE,
      payload: null,
      error: 'An error occured while fetching suburbs',
    })
  }
}

//getBusinessesBySubandCats
export const getBusinessesBySubandCats = async (
  dispatch,
  suburb,
  categories,
) => {
  //loading
  dispatch({ type: FETCHING_BUSINESSES, payload: null, error: null })
  try {
    const response = await getBusinessesBySubandCatsService(
      suburb,
      categories || [],
    )
    if (!!response?.success) {
      dispatch({
        type: FETCHING_BUSINESSES_SUCCESS,
        payload: response.results,
      })
    } else {
      dispatch({
        type: FETCHING_BUSINESSES_FAILURE,
        payload: null,
        error: 'An error occured while fetching businesses',
      })
    }
  } catch (error) {
    dispatch({
      type: FETCHING_BUSINESSES_FAILURE,
      payload: null,
      error: 'An error occured while fetching businesses',
    })
  }
}
