import { Route, Switch } from 'react-router-dom'

import Home from '../screens/Home'

// import Login from '../components/Login/Login'

import ResultsIndex from '../screens/ResultsIndex'

export default function AllRoutes() {
  return (
    <>
      <Switch>
        <Route exact path="/results">
          <ResultsIndex />
        </Route>
        {/* <Route exact path="/login">
          <Login />
        </Route> */}
        <Route path="/*">
          <Home />
        </Route>

        {/* <Route exact path="/attractions">
          <AttractionIndex />
        </Route> */}
      </Switch>
    </>
  )
}
