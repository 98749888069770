import styles from './Navbar.module.css'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
// import { getCategories } from '../services'
const Tabs = styled.li`
  cursor: pointer;
  padding-left: 8px;
  padding-right: 12px;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: ${(props) =>
    props.tab === props.t ? '2px solid white;' : 'none'};
  font-weight: ${(props) => (props.tab === props.t ? '500' : 'none')};
  font-size: ${(props) => (props.tab === props.t ? '14px' : '13px')};
`
export const Navbar = () => {
  const [tab, setTabs] = useState(1)
  const [user, setUser] = useState(false)

  useEffect(() => {
    // let data = JSON.parse(localStorage.getItem('login'))

    // if (data || 11==11) {
    if (11 == 11) {
      setUser(true)
    } else {
      setUser(false)
    }
  }, [])

  // const majorCategories = getCategories()
  const isLargeDevice = window.innerWidth >= 768

  return (
    <>
      <div className={styles.main}>
        <div className={styles.navbarContainer}>
          <div className={styles.navbarUpperSection}>
            <div>
              <Link to="/">
                {/* <h4 className={styles.whiteText}>Best Businesses In Town</h4>
                 */}
                {/* logo */}

                {isLargeDevice ? (
                  <img
                    src={require('../assets/images/logo/white.svg').default}
                    alt="Best Businesses In Town"
                    height={100}
                    width={100}
                    className={styles.logo}
                  />
                ) : (
                  // <img
                  //   src={require('../assets/images/favicons/Android.png')}
                  //   alt="Best Businesses In Town"
                  //   height={30}
                  //   width={30}
                  //   className={styles.logo}
                  // />
                  <h4 className="text-white mt-2 mb-3">Best In Town</h4>
                )}
              </Link>
            </div>
            <div className={styles.navbarUpperSectionItems}>
              <ul>
                <a href="#list-business" className={styles.propertyList}>
                  <button>List your business</button>
                </a>
              </ul>
            </div>
          </div>
          {/* <div className={styles.navbarLowerSection}>
            <ul>
               {majorCategories.map((cat, index) => (
                <Tabs onClick={() => setTabs(index + 1)} t={cat.id} tab={tab}>
                  <i className={`fa fa-${cat.icon} fa-2x`} />
                  <Link to={`/${cat.name}`} className={styles.Link}>
                    <p className={styles.text}>{cat.name}</p>
                  </Link>
                </Tabs>
              ))} 
              &nbsp;
            </ul>
          </div> */}
        </div>
      </div>
    </>
  )
}
