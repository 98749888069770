import { Link } from 'react-router-dom'
import styled from 'styled-components'
import style from './Footer.module.css'
import { Box, Button, Chip, Grid, Paper, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { toTitleCase } from 'utils'

const FooterBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgb(255, 255, 255);
  padding-bottom: 50px;
`

function distributeItems(items) {
  const divs = [[], [], []]
  items.forEach((item, index) => {
    const columnIndex = index % 3 // Calculate the column index based on the item's position
    divs[columnIndex].push(item)
  })
  return divs
}

export default function Footer() {
  const categoriesState = useSelector((state) => state.categories)
  const divs = distributeItems(categoriesState?.categories)
  return (
    <FooterBox>
      <div className={style.box1}>
        <h3>Save time, save money!</h3>
        <p>Best in Town offers you the best only</p>
        <h6>Request to be listed a business</h6>
        <div className={style.text} id="list-business">
          <div className={style.box}>
            <input
              type="text"
              class="form-control"
              id="inputPassword"
              placeholder="Business Name"
            />
            <input
              type="email"
              class="form-control"
              id="inputPassword"
              placeholder="Email"
            />
            <button className="btn btn-warning">Request</button>
            {/* <Button
              variant="contained"
              className="p-4"
              size="small"
              // style={{
              //   backgroundColor: '#fcbf49',
              //   color: 'white',
              //   borderRadius: '0px',
              // }}
            >
              Request to be listed
            </Button> */}
          </div>
          <div></div>
        </div>
      </div>

      <div className="text-center">
        <div className="row mt-2">
          {divs.map((column, index) => (
            <div className="col-4 col-md-4 col-xs-4" key={index}>
              {column.map((item, idx) => (
                <div className="mt-1">
                  <Chip key={idx} label={toTitleCase(item)} />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* <div className={style.footer}>
        <div className="row">
          {divs.map((column, index) => (
            <div className="col-md-4" key={index}>
              {column.map((item, idx) => (
                <div key={idx}>{item}</div>
              ))}
            </div>
          ))}
        </div>
      </div> */}
      <div className={style.text2}>
        <h4 className={style.h}>Best in Town</h4>
        <p className={style.p}>
          Copyright © 2023 BestinTown™. All rights reserved.
        </p>
        {/* <div className={style.logo}>
                  <img src="https://t-cf.bstatic.com/static/img/tfl/group_logos/logo_booking/27c8d1832de6a3123b6ee45b59ae2f81b0d9d0d0.png" alt="" />
                  <img src="https://t-cf.bstatic.com/static/img/tfl/group_logos/logo_priceline/f80e129541f2a952d470df2447373390f3dd4e44.png" alt="" />
                  <img src="https://t-cf.bstatic.com/static/img/tfl/group_logos/logo_kayak/83ef7122074473a6566094e957ff834badb58ce6.png" alt="" />
                  <img src="https://t-cf.bstatic.com/static/img/tfl/group_logos/logo_agoda/1c9191b6a3651bf030e41e99a153b64f449845ed.png" alt="" />
                  <img src="https://t-cf.bstatic.com/static/img/tfl/group_logos/logo_rentalcars/6bc5ec89d870111592a378bbe7a2086f0b01abc4.png" alt="" />
                  <img src="https://t-cf.bstatic.com/static/img/tfl/group_logos/logo_opentable/a4b50503eda6c15773d6e61c238230eb42fb050d.png" alt="" />
              </div> */}
      </div>
    </FooterBox>
  )
}
