// const API_URL = 'http://localhost:5004'
const API_URL = 'https://www.backendlive.bestbusinessesintown.com'
export const getCategoriesService = async () => {
  try {
    const response = await fetch(API_URL + '/api/businesses/unique-cats')
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error:', error)
    return []
  }
}

export const getSuburbsPerCategoryArrayService = async (categoryArray) => {
  try {
    const response = await fetch(
      API_URL +
        `/api/businesses/unique-suburbs-per-cat/?categories=${categoryArray.toString()}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    const data = await response.json()
    console.log('data', data)
    return data
  } catch (error) {
    console.error('Error:', error)
    return []
  }
}

export const getBusinessesBySubandCatsService = async (suburb, categories) => {
  try {
    const response = await fetch(
      API_URL +
        `/api/businesses/businesses-by-cat-sub/?suburb=${suburb}&categories=${categories.toString()}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    const data = await response.json()
    console.log('data', data)
    return data
  } catch (error) {
    console.error('Error:', error)
    return []
  }
}

export const getTopSearchedCities = () => {
  //logic to fetch
  //return
  return [
    {
      id: 1,
      name: 'Acacia City',
      image:
        'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/29/37/53/9f/nautilus-outdoor-pool.jpg?w=1200&h=-1&s=1',
      highlights: '',
    },
    {
      id: 2,
      name: 'Zillmere',
      image:
        'https://images.mrandmrssmith.com/images/698x522/2945639-spicers-balfour-hotel-brisbane-australia.jpg',
      highlights: '',
    },
    {
      id: 3,
      name: 'Mombasa',
      image:
        'https://imgix.theurbanlist.com/content/article/beautiful-cafes-brisbane-3.png?auto=compress&w=1200&h=630&fit=crop',
      highlights: '',
    },
  ]
}

// export const fetchBusinesses = async (file) => {
//   const response = await fetch(file)
//   console.log('r', response)
//   const res = await response.json()
//   return res
// }
