export const FETCHING_CATEGORIES = 'FETCHING_CATEGORIES'
export const FETCHING_CATEGORIES_SUCCESS = 'FETCHING_CATEGORIES_SUCCESS'
export const FETCHING_CATEGORIES_FAILURE = 'FETCHING_CATEGORIES_FAILURE'
export const UPDATING_SELECTED_CATEGORIES = 'UPDATING_SELECTED_CATEGORIES'
export const FETCHING_SUBURBS = 'FETCHING_SUBURBS'
export const FETCHING_SUBURBS_SUCCESS = 'FETCHING_SUBURBS_SUCCESS'
export const FETCHING_SUBURBS_FAILURE = 'FETCHING_SUBURBS_FAILURE'
export const UPDATING_DETECTED_SUBURB = 'UPDATING_DETECTED_SUBURB'
export const UPDATING_SELECTED_SUBURBS = 'UPDATING_SELECTED_SUBURBS'
export const FETCHING_BUSINESSES = 'FETCHING_BUSINESSES'
export const FETCHING_BUSINESSES_SUCCESS = 'FETCHING_BUSINESSES_SUCCESS'
export const FETCHING_BUSINESSES_FAILURE = 'FETCHING_BUSINESSES_FAILURE'
