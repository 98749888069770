import React from 'react'
import Svg from '../common/Svg'
import Paths from '../common/SvgPaths'
import styles from '../style/styleInner.module.css'
const BannerPanelSection = () => {
  return (
    <div className={styles.FourthSection}>
      <h3>We've got you covered</h3>
      <div className={styles.outterFlex}>
        <div className={styles.innerFlex}>
          <div>
            <Svg path={Paths.one} color="green" size="30px" />
          </div>
          <div>
            <h5>Discover Local Businesses</h5>
            <div>
              Explore a world of local businesses, from restaurants to daycares
              and more
            </div>
          </div>
        </div>

        <div className={styles.innerFlex}>
          <div>
            <Svg path={Paths.two} color="green" size="30px" />
          </div>
          <div>
            <h5>Fast and flexible</h5>
            <div>
              Search through millions to find your desired business,
              effortlessly
            </div>
          </div>
        </div>

        <div className={styles.innerFlex}>
          <div>
            <Svg path={Paths.three} color="green" size="30px" />
          </div>
          <div>
            <h5>Supporting Local Economy</h5>
            <div>
              Empowering local businesses and communities, one listing at a time
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerPanelSection
