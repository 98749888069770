 const Paths = {
    two: `M22.5 17.25a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0zm1.5 0a6.75 6.75 0 1 0-13.5 0 6.75 6.75 0 0 0 13.5 0zm-4.676-2.194l-2.905 3.873h-.002l-1.499-1.5a.75.75 0 1 0-1.06 1.061l1.5 1.5a1.502 1.502 0 0 0 2.26-.16l2.906-3.874a.75.75 0 0 0-1.2-.9zM8.25 16.5h-6a.75.75 0 0 1-.75-.75V3.765a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 .75.75V8.25a.75.75 0 0 0 1.5 0V3.765a2.25 2.25 0 0 0-2.25-2.25H2.25A2.25 2.25 0 0 0 0 3.765V15.75A2.25 2.25 0 0 0 2.25 18h6a.75.75 0 0 0 0-1.5zm-7.5-9h16.5V6H.75v1.5zm5.243-3.75v-3a.75.75 0 0 0-1.5 0v3a.75.75 0 0 0 1.5 0zm7.5 0v-3a.75.75 0 0 0-1.5 0v3a.75.75 0 0 0 1.5 0z`,
     one: `M13.5 3a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM15 3a3 3 0 1 0-6 0 3 3 0 0 0 6 0zm6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm1.5 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0zM6 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm1.5 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0zM21 15a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm1.5 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0zm-9-3.75a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm1.5 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0zM6 15a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm1.5 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0zm10.066 1.277a7.5 7.5 0 0 1-3.077 2.05.75.75 0 0 0 .498 1.415 9 9 0 0 0 3.693-2.46.75.75 0 1 0-1.114-1.005zm1.798-6.466c.177.922.183 1.869.015 2.792a.75.75 0 1 0 1.476.268c.2-1.106.194-2.24-.019-3.344a.75.75 0 1 0-1.472.284zm-5.337-5.784a7.5 7.5 0 0 1 3.54 2.196.75.75 0 0 0 1.113-1.004 9.002 9.002 0 0 0-4.247-2.636.75.75 0 1 0-.406 1.444zM6.434 6.223a7.5 7.5 0 0 1 3.539-2.196.75.75 0 1 0-.406-1.444A9.001 9.001 0 0 0 5.32 5.219a.75.75 0 0 0 1.114 1.004zM4.636 12.69a7.602 7.602 0 0 1 0-2.878.75.75 0 1 0-1.472-.284 9.102 9.102 0 0 0 0 3.446.75.75 0 0 0 1.472-.284zm4.876 5.639a7.517 7.517 0 0 1-3.035-2.005.75.75 0 0 0-1.106 1.014 9.017 9.017 0 0 0 3.641 2.405.75.75 0 1 0 .5-1.414zM7.31 21.872A1.5 1.5 0 0 0 8.672 24h6.656a1.5 1.5 0 0 0 1.362-2.128l-3.314-8.217c-.361-.785-1.252-1.114-2.005-.767a1.5 1.5 0 0 0-.733.734l-3.343 8.283zm1.377.595l3.328-8.25-.015.033 3.313 8.217.015.033H8.672z`,
     three: `m 14.44 15 c 0.225 -0.239 0.225 -0.611 0 -0.85 a 0.58 0.58 0 0 0 -0.83 0 c -0.367 0.33 -0.847 0.506 -1.34 0.49 c -0.493 0.016 -0.973 -0.16 -1.34 -0.49 a 0.58 0.58 0 0 0 -0.83 0 c -0.23 0.237 -0.23 0.613 0 0.85 c 0.58 0.57 1.367 0.88 2.18 0.86 c 0.806 0.015 1.585 -0.295 2.16 -0.86 z m 7.17 -6.49 c -0.073 -0.005 -0.147 -0.005 -0.22 0 L 21.25 8.19 C 19.245 3.091 13.487 0.583 8.388 2.588 C 5.716 3.638 3.631 5.795 2.67 8.5 H 2.39 a 2.16 2.16 0 0 0 -2.16 2.17 v 2.82 a 2.16 2.16 0 0 0 4.32 0 v -2.82 C 4.538 10.153 4.343 9.657 4 9.27 A 8.41 8.41 0 0 1 19.85 8.82 l 0.17 0.39 c -0.388 0.386 -0.601 0.913 -0.59 1.46 v 2.82 c 0.002 0.466 0.157 0.92 0.44 1.29 c -0.898 2.406 -2.847 4.27 -5.29 5.06 a 2.63 2.63 0 0 0 -4.715 2.332 A 2.63 2.63 0 0 0 14.83 21.35 c 2.86 -0.875 5.18 -2.985 6.32 -5.75 c 0.145 0.032 0.292 0.049 0.44 0.05 c 1.193 0 2.16 -0.967 2.16 -2.16 v -2.82 c 0 -1.185 -0.955 -2.149 -2.14 -2.16 z m -18.56 5 a 0.66 0.66 0 0 1 -1.32 0 v -2.84 a 0.66 0.66 0 0 1 1.32 0 z m 9.2 8.65 a 1.13 1.13 0 0 1 0 -2.26 c 0.613 0 1.114 0.488 1.13 1.1 c 0 0.626 -0.504 1.135 -1.13 1.14 z m 10 -8.65 a 0.66 0.66 0 0 1 -1.32 0 v -2.84 a 0.67 0.67 0 0 1 0.66 -0.66 c 0.365 0 0.66 0.295 0.66 0.66 z M 7.33 8.88 C 7.12 9.09 7.002 9.374 7 9.67 c 0.002 0.224 0.068 0.443 0.19 0.63 c 0.124 0.186 0.302 0.329 0.51 0.41 c 0.205 0.088 0.431 0.112 0.65 0.07 c 0.215 -0.049 0.412 -0.156 0.57 -0.31 C 9.08 10.313 9.188 10.11 9.23 9.89 C 9.27 9.672 9.25 9.447 9.17 9.24 A 1.13 1.13 0 0 0 7.33 8.88 z m 7.88 0 c -0.381 0.379 -0.44 0.974 -0.14 1.42 c 0.254 0.374 0.705 0.562 1.15 0.48 c 0.219 -0.046 0.42 -0.153 0.58 -0.31 c 0.153 -0.16 0.258 -0.362 0.3 -0.58 C 17.14 9.668 17.106 9.44 17 9.24 C 16.916 9.036 16.773 8.862 16.59 8.74 C 16.414 8.626 16.21 8.56 16 8.55 c -0.297 0 -0.581 0.119 -0.79 0.33 z`,
     like: `M 5.239 22.5 c 0.461 0.008 0.92 0.076 1.363 0.202 l 3.634 1.038 c 0.602 0.172 1.226 0.26 1.853 0.26 h 4.885 a 6.75 6.75 0 0 0 6.716 -6.075 l 0.303 -5.131 a 5.252 5.252 0 0 0 -3.967 -5.365 l -1.477 -0.324 A 0.745 0.745 0 0 1 18 6.382 V 3 a 3 3 0 1 0 -6 0 v 1.554 a 6.75 6.75 0 0 1 -6.75 6.75 a 0.75 0.75 0 0 0 -0.75 0.75 v 9.697 c 0 0.41 0.329 0.744 0.739 0.75 Z m 0.022 -1.499 l -0.011 0.75 H 6 v -9.697 l -0.75 0.75 a 8.25 8.25 0 0 0 8.25 -8.25 V 3 a 1.5 1.5 0 0 1 3 0 v 3.382 a 2.25 2.25 0 0 0 1.709 2.184 l 1.475 0.323 a 3.745 3.745 0 0 1 2.811 3.823 l -0.3 5.094 c -0.267 2.652 -2.525 4.694 -5.221 4.694 h -4.885 a 5.25 5.25 0 0 1 -1.44 -0.202 L 7.013 21.26 A 6.747 6.747 0 0 0 5.261 21 Z M 0.75 10.5 h 4.5 l -0.75 -0.75 v 13.5 l 0.75 -0.75 H 0.75 l 0.75 0.75 V 9.75 l -0.75 0.75 Z m 0 -1.5 a 0.75 0.75 0 0 0 -0.75 0.75 v 13.5 c 0 0.414 0.336 0.75 0.75 0.75 h 4.5 a 0.75 0.75 0 0 0 0.75 -0.75 V 9.75 A 0.75 0.75 0 0 0 5.25 9 H 0.75 Z`,
     confirm:`M 56.62 93.54 a 4 4 0 0 1 -2.83 -1.18 L 28.4 67 a 4 4 0 1 1 5.65 -5.65 l 22.13 22.1 l 33 -44 a 4 4 0 1 1 6.4 4.8 L 59.82 91.94 a 4.06 4.06 0 0 1 -2.92 1.59 Z M 128 64 c 0 -35.346 -28.654 -64 -64 -64 C 28.654 0 0 28.654 0 64 c 0 35.346 28.654 64 64 64 c 35.33 -0.039 63.961 -28.67 64 -64 Z m -8 0 c 0 30.928 -25.072 56 -56 56 S 8 94.928 8 64 S 33.072 8 64 8 c 30.914 0.033 55.967 25.086 56 56 Z`,
     freecancill: `M 22.5 17.25 a 5.25 5.25 0 1 1 -10.5 0 a 5.25 5.25 0 0 1 10.5 0 Z m 1.5 0 a 6.75 6.75 0 1 0 -13.5 0 a 6.75 6.75 0 0 0 13.5 0 Z m -4.676 -2.194 l -2.905 3.873 h -0.002 l -1.499 -1.5 a 0.75 0.75 0 1 0 -1.06 1.061 l 1.5 1.5 a 1.502 1.502 0 0 0 2.26 -0.16 l 2.906 -3.874 a 0.75 0.75 0 0 0 -1.2 -0.9 Z M 8.25 16.5 h -6 a 0.75 0.75 0 0 1 -0.75 -0.75 V 3.765 a 0.75 0.75 0 0 1 0.75 -0.75 h 13.5 a 0.75 0.75 0 0 1 0.75 0.75 V 8.25 a 0.75 0.75 0 0 0 1.5 0 V 3.765 a 2.25 2.25 0 0 0 -2.25 -2.25 H 2.25 A 2.25 2.25 0 0 0 0 3.765 V 15.75 A 2.25 2.25 0 0 0 2.25 18 h 6 a 0.75 0.75 0 0 0 0 -1.5 Z m -7.5 -9 h 16.5 V 6 H 0.75 v 1.5 Z m 5.243 -3.75 v -3 a 0.75 0.75 0 0 0 -1.5 0 v 3 a 0.75 0.75 0 0 0 1.5 0 Z m 7.5 0 v -3 a 0.75 0.75 0 0 0 -1.5 0 v 3 a 0.75 0.75 0 0 0 1.5 0 Z`,
     meetingPoint: `M 15 8.25 a 3 3 0 1 1 -6 0 a 3 3 0 0 1 6 0 Z m 1.5 0 a 4.5 4.5 0 1 0 -9 0 a 4.5 4.5 0 0 0 9 0 Z M 12 1.5 a 6.75 6.75 0 0 1 6.75 6.75 c 0 2.537 -3.537 9.406 -6.75 14.25 c -3.214 -4.844 -6.75 -11.713 -6.75 -14.25 A 6.75 6.75 0 0 1 12 1.5 Z M 12 0 a 8.25 8.25 0 0 0 -8.25 8.25 c 0 2.965 3.594 9.945 7 15.08 a 1.5 1.5 0 0 0 2.5 0 c 3.406 -5.135 7 -12.115 7 -15.08 A 8.25 8.25 0 0 0 12 0 Z`,
     correct: `M 56.33 100 a 4 4 0 0 1 -2.82 -1.16 L 20.68 66.12 a 4 4 0 1 1 5.64 -5.65 l 29.57 29.46 l 45.42 -60.33 a 4 4 0 1 1 6.38 4.8 l -48.17 64 a 4 4 0 0 1 -2.91 1.6 Z`,
     wrong: `M 5.03 20.03 l 15 -15 a 0.75 0.75 0 0 0 -1.06 -1.06 l -15 15 a 0.75 0.75 0 1 0 1.06 1.06 Z m -1.06 -15 l 15 15 a 0.75 0.75 0 1 0 1.06 -1.06 l -15 -15 a 0.75 0.75 0 0 0 -1.06 1.06 Z`,
    info:`M 14.25 15.75 h -0.75 a 0.75 0.75 0 0 1 -0.75 -0.75 v -3.75 a 1.5 1.5 0 0 0 -1.5 -1.5 h -0.75 a 0.75 0.75 0 0 0 0 1.5 h 0.75 V 15 a 2.25 2.25 0 0 0 2.25 2.25 h 0.75 a 0.75 0.75 0 0 0 0 -1.5 Z M 11.625 6 a 1.125 1.125 0 1 0 0 2.25 a 1.125 1.125 0 0 0 0 -2.25 a 0.75 0.75 0 0 0 0 1.5 a 0.375 0.375 0 1 1 0 -0.75 a 0.375 0.375 0 0 1 0 0.75 a 0.75 0.75 0 0 0 0 -1.5 Z M 22.5 12 c 0 5.799 -4.701 10.5 -10.5 10.5 S 1.5 17.799 1.5 12 S 6.201 1.5 12 1.5 S 22.5 6.201 22.5 12 Z m 1.5 0 c 0 -6.627 -5.373 -12 -12 -12 S 0 5.373 0 12 s 5.373 12 12 12 s 12 -5.373 12 -12 Z`
}
export default Paths
