import { combineReducers } from 'redux'

import attractionReducer from './AttractionReducer'

import debounceReducer from './debounceReducer'

import categoriesReducer from './categoriesReducer'

import suburbsReducer from './suburbsReducer'

import businessesReducer from './businessReducer'

export default combineReducers({
  attraction: attractionReducer,
  debounce: debounceReducer,
  categories: categoriesReducer,
  suburbs: suburbsReducer,
  businesses: businessesReducer,
})
